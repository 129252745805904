import BasicConfig from './admin/BasicConfig.js' // 基础设置
import UserManage from './admin/UserManage.js' // 用户管理
import ContentConfig from './admin/contentConfig.js' // 内容管理
import OrderConfig from './admin/OrderConfig.js' // 订单管理
import StudyMange from './admin/StudyMange.js' // 学习管理
import PromotionManage from './admin/PromotionManage.js' // 促销管理
import Search from './admin/Search.js' // 综合查询
import Certificate from './admin/Certificate.js' // 综合查询
import AuditManage from './admin/AuditManage.js' // 证书管理
import MessageManage from './admin/MessageManage.js' // 消息管理
import curriculum from './admin/curriculum.js' // 课程管理
import Examination from './admin/Examination.js' // 考试管理
import ExamSearch from './admin/ExamSearch.js' // 考试查询
import ReportStatistics from './admin/ReportStatistics.js' // 报表统计
import Commodity from './admin/Commodity.js' // 商品管理
import Mechanism from './admin/Mechanism.js' // 机构管理
import Subjective from './admin/Subjective.js' // 主观题管理
import Registration from './admin/Registration.js' // 报名管理

export default [{
        path: '/',
        redirect: 'home'
    },
    // 默认的首页
    {
        path: 'home',
        component: () =>
            import ('@/views/admin/home.vue')
    },
    /* 用户管理 ***************************************************/
    {
        path: 'UserManage',
        component: () =>
            import ('@/views/routerView.vue'),
        children: UserManage
    },
    /* 用户管理 Esc ***************************************************/


    /* 基础配置 ***************************************************/
    {
        path: 'BasicConfig',
        component: () =>
            import ('@/views/routerView.vue'),
        children: BasicConfig
    },
    /* 基础配置 Esc ***************************************************/

    /* 学习管理 ***************************************************/
    {
        path: 'StudyMange',
        component: () =>
            import ('@/views/routerView.vue'),
        children: StudyMange
    },
    /* 学习管理 Esc ***************************************************/

    /* 综合查询 ***************************************************/
    {
        path: 'Search',
        component: () =>
            import ('@/views/routerView.vue'),
        children: Search
    },
    /* 综合查询 Esc ***************************************************/

    /* 内容管理 ***************************************************/
    {
        path: 'ContentConfig',
        name:"contentConfig",
        component: () =>
            import ('@/views/routerView.vue'),
        children: ContentConfig
    },
    /* 内容管理 Esc ***************************************************/

    /* 订单管理 ***************************************************/
    {
        path: 'OrderConfig',
        component: () =>
            import ('@/views/routerView.vue'),
        children: OrderConfig
    },
    /* 订单管理 Esc ***************************************************/

    /* 促销管理 ***************************************************/
    {
        path: 'PromotionManage',
        component: () =>
            import ('@/views/routerView.vue'),
        children: PromotionManage
    },
    /* 促销管理 Esc ***************************************************/

    /* 证书管理 ***************************************************/
    {
        path: 'Certificate',
        component: () =>
            import ('@/views/routerView.vue'),
        children: Certificate
    },
    /* 证书管理 Esc ***************************************************/

    /* 消息管理 ***************************************************/
    {
        path: 'MessageManage',
        component: () =>
            import ('@/views/routerView.vue'),
        children: MessageManage
    },
    /* 消息管理 Esc ***************************************************/
    
    
    /* 证书管理 Esc ***************************************************/
    {
        path: 'AuditManage',
        component: () =>
            import ('@/views/routerView.vue'),
        children: AuditManage
    },
    /* 证书管理 Esc ***************************************************/

    /* 课程管理 ***************************************************/
    {
        path: 'curriculum',
        component: () =>
            import ('@/views/routerView.vue'),
        children: curriculum
    },
    /* 课程管理 Esc ***************************************************/
    
    /* 商品管理 ***************************************************/
    {
      path: 'Commodity',
      component: () =>
          import ('@/views/routerView.vue'),
      children: Commodity
    },
    /* 商品管理 Esc ***************************************************/
    
    /* 考试管理 ***************************************************/
    {
      path: 'Examination',
      component: () =>
          import ('@/views/routerView.vue'),
      children: Examination
    },
    /* 考试管理 Esc ***************************************************/

    /* 考试查询 ***************************************************/
    {
      path: 'ExamSearch',
      component: () =>
          import ('@/views/routerView.vue'),
      children: ExamSearch
    },
    /* 考试管理 Esc ***************************************************/

    /* 报表统计 ***************************************************/
    {
        path: 'ReportStatistics',
        component: () =>
            import ('@/views/routerView.vue'),
        children: ReportStatistics
      },
    /* 报表统计 Esc ***************************************************/

    /* 操作日记 ***************************************************/
    {
        path: 'OperationLog',
        component: () =>
            import ('@/views/admin/OperationLog.vue'),
        // children: OperationLog
    },
    /* 操作日记 Esc ***************************************************/
    
    /* 操作记录 ***************************************************/
    {
        path: 'OperationRecord',
        component: () =>
            import ('@/views/admin/OperationRecord.vue'),
        // children: OperationRecord
    },
    /* 操作记录 Esc ***************************************************/
    

    /* 机构管理 ***************************************************/
    {
        path: 'Mechanism',
        component: () =>
        import ('@/views/routerView.vue'),
        children: Mechanism
    },
    /* 机构管理 Esc ***************************************************/

    /* 主观题管理 ***************************************************/
    {
        path: 'Subjective',
        component: () =>
        import ('@/views/routerView.vue'),
        children: Subjective
    },
    /* 机构管理 Esc ***************************************************/

    /* 移植报名管理 ***************************************************/
    {
        path: 'Registration',
        component: () =>
        import ('@/views/routerView.vue'),
        children: Registration
    },
    /* 移植报名管理 Esc ***************************************************/
    
    /* 数据分析 ***************************************************/
    {
        path: 'DataAnalyse',
        component: () =>
            import ('@/views/admin/DataAnalyse.vue'),
        // children: DataAnalyse
    },
    /* 数据分析 Esc ***************************************************/

    /* 数据分析 ***************************************************/
    {
        path: 'DataCenter',
        component: () =>
            import ('@/views/admin/DataCenter/index.vue'),
    },
    {
        path: 'StudyDataCenter',
        component: () =>
            import ('@/views/admin/DataCenter/StudyDataCenter.vue'),
    },
    /* 数据分析 Esc ***************************************************/
  

    // 详情模板
    {
        path: 'adminTestDetail',
        name: 'adminTestDetail',
        meta: {
            FullScreen: true
        },
        component: () =>
            import ('@/views/admin/demo/TestDetail.vue')
    },
    { //头部示例
        path: 'TestHeader',
        name: 'TestHeader',
        component: () =>
            import ('@/views/admin/demo/TestHeader.vue')
    },
    { //表格示例
        path: 'tableList',
        name: 'tableList',
        component: () =>
            import ('@/views/admin/demo/tableList.vue')
    },
    { //上传示例
        path: 'upload',
        name: 'upload',
        component: () =>
            import ('@/views/admin/demo/upload.vue')
    },
    { //表格导入示例
        path: 'importExcel',
        name: 'importExcel',
        component: () =>
            import ('@/views/admin/demo/importExcel.vue')
    },
    { //省市区级联选择示例
        path: 'provice',
        name: 'provice',
        component: () =>
            import ('@/views/admin/demo/provice.vue')
    },
    { //卡罗莱纳数据表
        path: 'KLLNTable',
        name: 'KLLNTable',
        component: () =>
            import ('@/views/admin/KLLNTable')
    },
    { // 系统申请管理
        path: 'systemApply',
        name: 'systemApply',
        component: () =>
            import ('@/views/admin/systemApply')
    },
    { // 动态统计报表列表
        path: 'intelligenceList',
        name: 'intelligenceList',
        component: () =>
            import ('@/views/admin/DataCenter/intelligenceList')
    },
    { // 动态统计报表详情
        path: 'intelligenceDetail',
        name: 'intelligenceDetail',
        component: () =>
            import ('@/views/admin/DataCenter/intelligenceDetail')
    },
    { // 运营部管理端首页
        path: 'OperationsHome',
        name: 'OperationsHome',
        component: () =>
            import ('@/views/admin/DataCenter/OperationsHome')
    },
    { // 结算统计
        path: 'settlementManage',
        name: 'settlementManage',
        component: () =>
            import ('@/views/admin/DataCenter/settlementManage')
    },
    { // 渠道配置
        path: 'channelManage',
        name: 'channelManage',
        component: () =>
            import ('@/views/admin/DataCenter/channelManage')
    },
    { // 渠道订单
        path: 'channelOrder',
        name: 'channelOrder',
        component: () =>
            import ('@/views/admin/DataCenter/channelOrder')
    },
    { // 结算记录
        path: 'channelRecord',
        name: 'channelRecord',
        component: () =>
            import ('@/views/admin/DataCenter/channelRecord')
    },
    { // 结算订单明细
        path: 'channelRecordDetail',
        name: 'channelRecordDetail',
        component: () =>
            import ('@/views/admin/DataCenter/channelRecordDetail')
    },
]