export default{
  // 公共的状态
  state: {
  },
  mutations: {
    setSearchConditions(state, obj) {
      const Name = 'history' + obj.modulesName
      state[Name] = obj.params;
    },
  },
  actions: {
  },
}
